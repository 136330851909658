import React from "react"
import { graphql , Link } from "gatsby"
import Layout from '../components/layout.jsx'
import CategoryList from '../components/categorylist.jsx'
import Seo from '../components/seo.jsx'
import {convertJPTime} from '../utils/convert_jp_time'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import HomeIcon from '@material-ui/icons/Home';

const useStyles = makeStyles((theme) => ({
  readingButton :  {
    textAlign: 'right'
  },
  blogTitleHead : {
    marginBottom : '10px'
  },
  blogDate: {
    marginBottom : '10px'
  },
  navNextMassage: {
    marginBottom : '10px',
    float:'left'
  },
  navPrevMassage: {
    marginBottom : '10px',
    float:'right'
  },
  navHomeButton : {
    boxShadow: 'none',
    backgroundImage: 'none',
    textAlign:'center'
  }
}))

export default function Template({ data, pageContext }) {

  const classes = useStyles();

  const { markdownRemark: post } = data
  const { previous, next } = pageContext
  const {title, logo} = data.site.siteMetadata
  return (
	<Layout title={title}>
      <Seo
        title={title}
        description={post.frontmatter.description || post.excerpt || ' '}
        image={logo}
        pathname={post.frontmatter.path}
        article
      />
      <h2 className={classes.blogTitleHead}>{post.frontmatter.title}</h2>
          <p className={classes.blogDate}>{
           convertJPTime(post.frontmatter.date)
          }</p>
          <div>
            <CategoryList categories={post.frontmatter.categories || []} />
          </div>
      <div
        className="blog-post-content"
        dangerouslySetInnerHTML={{ __html: post.html }}
      />
      <Grid container spacing={3}>

        <Grid item xs>
        　<p className={classes.navNextMassage}>&lt;</p>
          {next &&
            <Link to={next.frontmatter.path}>
              {next.frontmatter.title}
            </Link>
            }
        </Grid>
        <Grid className={classes.navHomeButton} item xs>
          <Link to='/'>
            <HomeIcon />
            </Link>
        </Grid>
        <Grid item xs>
          <p className={classes.navPrevMassage}>&gt;</p>
           {previous &&
              <Link to={previous.frontmatter.path}>
                {previous.frontmatter.title}
              </Link>
            }
        </Grid>
      </Grid>
	</Layout>
  )
}
export const pageQuery = graphql`
  query BlogPostByPath($pathSlug: String!) {
    site {
      siteMetadata {
        title
        logo
      }
    }
    markdownRemark(frontmatter: { path: { eq: $pathSlug } }) {
      html
      excerpt(truncate: true)
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        categories
      }
    }
  }
`